"use client";
import { EnrichmentType } from "@/app/types/db";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

export interface EnrichmentContextProps {
  csvData: Record<string, string | number | undefined>[];
  setCsvData: Dispatch<
    SetStateAction<Record<string, string | number | undefined>[]>
  >;
  fieldMappings: { [key: string]: string };
  setFieldMappings: Dispatch<
    SetStateAction<{
      [key: string]: string;
    }>
  >;
  enrichmentType: EnrichmentType;
  setEnrichmentType: Dispatch<SetStateAction<EnrichmentType>>;
  listName: string;
  setListName: Dispatch<SetStateAction<string>>;
  prospectCount: number;
  setProspectCount: Dispatch<SetStateAction<number>>;
  emailTypes: string[];
  setEmailTypes: Dispatch<SetStateAction<string[]>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
}

export const EnrichmentContext = createContext<EnrichmentContextProps | null>(
  null
);

export const EnrichmentProvider = ({ children }: { children: ReactNode }) => {
  const [csvData, setCsvData] = useState<
    Record<string, string | number | undefined>[]
  >([]);
  const [fieldMappings, setFieldMappings] = useState<{ [key: string]: string }>(
    {}
  );
  const [enrichmentType, setEnrichmentType] = useState<EnrichmentType>("none");
  const [listName, setListName] = useState<string>("");
  const [prospectCount, setProspectCount] = useState<number>(0);
  const [emailTypes, setEmailTypes] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");

  return (
    <EnrichmentContext.Provider
      value={{
        csvData,
        setCsvData,
        fieldMappings,
        setFieldMappings,
        enrichmentType,
        setEnrichmentType,
        listName,
        setListName,
        prospectCount,
        setProspectCount,
        emailTypes,
        setEmailTypes,
        description,
        setDescription,
      }}
    >
      {children}
    </EnrichmentContext.Provider>
  );
};
