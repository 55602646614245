"use client";
import { useContext } from "react";
import { FaMoon, FaSun } from "react-icons/fa"; // Import the icons
import { ThemeContext } from "../context/theme-context";

export const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleTheme}
      className="fixed bottom-2 left-2 md:bottom-5 md:left-5 p-2 bg-gray-200 dark:bg-gray-700 rounded-full z-50"
      aria-label="Toggle Theme"
    >
      {theme === "light" ? (
        <FaMoon className="text-black" />
      ) : (
        <FaSun className="text-white" />
      )}
    </button>
  );
};

export default ThemeToggle;
