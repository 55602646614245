"use client";
import { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext<{
  theme: string;
  toggleTheme: () => void;
}>({
  theme: "light",
  toggleTheme: () => {},
});

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState("light"); // Default value

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Retrieve theme from localStorage once window is available
      const storedTheme = localStorage.getItem("theme");
      const initialTheme = storedTheme || "light";
      setTheme(initialTheme);
      // Apply the theme to the document
      const root = window.document.documentElement;
      root.classList.toggle("dark", initialTheme === "dark");
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Apply the theme to the document whenever it changes
      const root = window.document.documentElement;
      root.classList.toggle("dark", theme === "dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      return newTheme;
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
