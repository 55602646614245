"use client";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
);

type StripeProviderProps = {
  children: React.ReactNode;
};

export const StripeProvider = ({ children }: StripeProviderProps) => {
  const options = {};
  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};
